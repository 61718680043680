import React from 'react'
import './samsungbanner.modules.scss'
import Dropdown from "react-dropdown";
import { changeLocale, IntlContextConsumer, useIntl } from "gatsby-plugin-intl";

export default function BannerComp() {
    const intl = useIntl();
    const language = intl.locale;
    const bannerMobile = require(`../../images/${process.env.GATSBY_PARTNER}/${process.env.GATSBY_COUNTRY.toUpperCase()}/${language}/banner-mobile.png`);
    const bannerTablet = require(`../../images/${process.env.GATSBY_PARTNER}/${process.env.GATSBY_COUNTRY.toUpperCase()}/${language}/banner-tablet.png`);
    const bannerDesktop = require(`../../images/${process.env.GATSBY_PARTNER}/${process.env.GATSBY_COUNTRY.toUpperCase()}/${language}/banner.png`);

    const onLangChange = (lang) => {
        let otConsentSdk = document.getElementById('onetrust-consent-sdk');
        if (otConsentSdk) {
            otConsentSdk.remove();
        }
        if (window.OneTrust != null) {
            window.OneTrust.Init();
            setTimeout(() => {
                window.OneTrust.LoadBanner();
                let toggleDisplay = document.getElementsByClassName(
                    'ot-sdk-show-settings',
                );
                if (window.OneTrust) {
                    // these language codes dont exist in geo rules
                    if (lang.value === 'nl-be' || lang.value === 'fr-be') {
                        lang.value = lang.value.substring(0, 2);
                    }
                    window.OneTrust.changeLanguage(lang.value);
                }
                for (const elem of toggleDisplay) {
                    elem.onclick = (event) => {
                        event.stopImmediatePropagation();
                        window.OneTrust.ToggleInfoDisplay();
                    };
                }
            }, 1000);
        }
        changeLocale(lang.value);
    };

    return (
        <>
            <div className='bannerWrapper'>
                <div className='d-flex justify-content-between p-30'>
                    <div className='samsung-logo' />
                    <div className="lang-wrap">
                    <IntlContextConsumer>
                        {({ languages, language: currentLocale }) =>
                        languages.length > 1 && (
                            <Dropdown
                                options={languages}
                                onChange={onLangChange}
                                value={currentLocale}
                                placeholder="Lang"
                                />
                        )}
                    </IntlContextConsumer>
                    </div>
                </div>
                <div className="home-banner">
                    <img
                        src={bannerMobile}
                        className="display-none display-mobile"
                        alt="Bolttech"
                    />
                    <img
                        src={bannerTablet}
                        className="display-none display-tablet tablet-banner-style"
                        alt="Bolttech"
                    />
                    <img
                        src={bannerDesktop}
                        className="display-none display-desktop"
                        alt="Bolttech"
                    />
                </div>
            </div>
        </>
    )
}
