import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import banner from '../../images/samsung/banner.jpg';
import BannerComp from '../SamsungBanner';
import ContentComp from '../SamsungContent';

export default function ContainerComp() {
    return (
        <Row className="content-wrapper h-100">
            <Col md="6">
                <BannerComp />
            </Col>
            <Col md="6">
                <ContentComp />
            </Col>
        </Row>
    )
}
