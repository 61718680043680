import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import PropTypes from 'prop-types';
import './modal.scss';
import closeIco from '../../images/bdp/close.svg';

function ModalPopup(props) {
  const closeDialog = () => {
    props.onClose();
  }

    return (
      <Bootstrap.Modal
        size={props.size || 'lg'}
        show={props.show}
        keyboard={false}
        backdrop="static"
        centered
      >
        <Bootstrap.ModalBody className='modalPopupBody'>
          {!props.hideCloseBtn && (
            <div className='modalPopupContainer'>
                <h1 className='modalPopupHeading'>{props.heading}</h1>
                <span className='modalClose' onClick={() => closeDialog()}>
                  <img alt="close" src={closeIco} />
                </span>
            </div>
          )}
          {props.children}
        </Bootstrap.ModalBody>
      </Bootstrap.Modal>
    );
}

ModalPopup.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  heading: PropTypes.string,
  hideCloseBtn: PropTypes.bool,
  size: PropTypes.string,
};

ModalPopup.defaultProps = {
  onClose: () => {},
  show: false,
  heading: '',
  size: 'lg',
  hideCloseBtn: false,
};

export default ModalPopup;
