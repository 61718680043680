import React from 'react';
import styles from './cookie.modules.scss';
import cookieIcon from '../../../images/cookie.svg';
import { useIntl } from "gatsby-plugin-intl";

const CookiePolicy = () => {
  const intl = useIntl();

  return (
      <div className={styles.cWrap}>
        <div className={styles.cText}>
          <div className={styles.cIcon}>
            <img src={cookieIcon} alt="Cookie" />
          </div>
          <div>{intl.formatMessage({ id: "cookiePolicy" })}</div>
        </div>
        <div className={styles.btnWrap}>
          {/* <SPButton
            buttonStyle="plain"
            icon="none"
            className={styles.btnLearnMore}
            onClick={() => moreAboutCookiePolicy()}
          >
            {intl.formatMessage({ id: "learnMoreBtn" })}
          </SPButton>
          <SPButton
            trackId="accept-policy"
            icon="none"
            onClick={() => handleAcceptPolicy()}
          >
            {intl.formatMessage({ id: "acceptPolicyBtn" })}
          </SPButton> */}
        </div>
      </div>
  );
};

export default CookiePolicy;
