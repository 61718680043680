import { motion } from 'framer-motion';
import PropTypes from "prop-types";
import React from 'react';

import './button.modules.scss';

function Button({ trackBtn, handleClick, className, children, id, style }) {

    return (
        <motion.button 
            whileHover={{ scale: 1.05 }} 
            whileTap={{ scale: 0.95 }} 
            id={id}
            type="button"
            style={style}
            className={`${className} btn`} 
            onClick={handleClick}
            data-track-btn={trackBtn}
        >
            {children}
        </motion.button>
    )
}

Button.propTypes = {
    handleClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
      ]).isRequired,
    id: PropTypes.string,
    trackBtn: PropTypes.string,
    style: PropTypes.object,
  };

export default Button